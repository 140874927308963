/* roboto-300 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/roboto-v15-latin-300.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Light"), local("Roboto-Light"), url("./fonts/roboto-v15-latin-300.eot?#iefix") format("embedded-opentype"), url("./fonts/roboto-v15-latin-300.woff2") format("woff2"), url("./fonts/roboto-v15-latin-300.woff") format("woff"), url("./fonts/roboto-v15-latin-300.ttf") format("truetype"), url("./fonts/roboto-v15-latin-300.svg#Roboto") format("svg");
  /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/roboto-v15-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Roboto"), local("Roboto-Regular"), url("./fonts/roboto-v15-latin-regular.eot#iefix") format("embedded-opentype"), url("./fonts/roboto-v15-latin-regular.woff2") format("woff2"), url("./fonts/roboto-v15-latin-regular.woff") format("woff"), url("./fonts/roboto-v15-latin-regular.ttf") format("truetype"), url("./fonts/roboto-v15-latin-regular.svg#Roboto") format("svg");
  /* Legacy iOS */
}
/* roboto-700 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/roboto-v15-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Bold"), local("Roboto-Bold"), url("./fonts/roboto-v15-latin-700.eot#iefix") format("embedded-opentype"), url("./fonts/roboto-v15-latin-700.woff2") format("woff2"), url("./fonts/roboto-v15-latin-700.woff") format("woff"), url("./fonts/roboto-v15-latin-700.ttf") format("truetype"), url("./fonts/roboto-v15-latin-700.svg#Roboto") format("svg");
  /* Legacy iOS */
}
/* Material Icons needed by the ultime theme of prime-ng*/
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/MaterialIcons-Regular.eot");
  /* For IE6-8 */
  src: local("Material Icons"), local("MaterialIcons-Regular"), url("./fonts/MaterialIcons-Regular.woff2") format("woff2"), url("./fonts/MaterialIcons-Regular.woff") format("woff"), url("./fonts/MaterialIcons-Regular.ttf") format("truetype");
}
.material-icons {
  font-family: "Material Icons", serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

/* MaterialDesignIcons.com

@font-face {
    font-family: 'Material Design Icons';
    src: url('./fonts/materialdesignicons-webfont.eot');
    src: url('./fonts/materialdesignicons-webfont.eot') format('embedded-opentype'),
        url('./fonts/materialdesignicons-webfont.woff2') format('woff2'),
        url('./fonts/materialdesignicons-webfont.woff') format('woff'),
        url('./fonts/materialdesignicons-webfont.ttf') format('truetype'),
        url('./fonts/materialdesignicons-webfont.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

*/
@font-face {
  font-family: "RobotoCondensed";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("RobotoCondensed-Regular"), url("./fonts/roboto-v15-latin-regular.eot#iefix") format("embedded-opentype"), url("./fonts/RobotoCondensed-Regular.ttf") format("truetype");
}
body {
  margin: 0;
  background-color: #f1f1f1;
}

arts2-login form {
  width: 100%;
  height: 100%;
}

arts2-login form mat-form-field {
  width: 100%;
  display: block;
  margin: 0 auto;
}

arts2-login form mat-form-field mat-icon {
  margin: 4px;
  cursor: pointer;
}

arts2-login form button {
  width: 100%;
}

.intro {
  display: flex !important;
  flex-direction: row !important;
  padding: 8px !important;
  margin: 8px 0 !important;
  align-items: center !important;
}
.intro mat-icon {
  color: #3f51b5;
}
.intro .text {
  margin: 0 16px;
}
.intro .text .italic {
  font-style: italic;
}
.intro .text p {
  margin: 4px 0;
}